// 引入路由模块并使用它
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  routes: [{
    path: '/',
    meta: {
      title: "在线表格"
    },
    component: () => import('../components/Home.vue'),
  }, {
    meta: {
      title: "报告文号"
    },
    path: '/report',
    component: () => import('../components/HelloReport.vue'),
  },
  {
    meta: {
      title: "询证函取号"
    },
    path: '/BANKCL',
    component: () => import('../components/BANKCL.vue'),
  },
    {
      meta: {
        title: "pdf在线预览"
      },
      path: '/PDF',
      component: () => import('../components/pdf.vue'),
    },
  {
    meta: {
      title: "信息查询"
    },
    path: '/qr',
    component: () => import('../components/qr.vue'),
  },
  {
    meta: {
      title: "快递寄送"
    },
    name: 'Express',
    path: '/express',
    component: () => import('../components/express.vue'),
  },
  {
    meta: {
      title: "申请快递寄送"
    },
    name: 'NewExpress',
    path: '/express/new',
    component: () => import('../components/components/NewExpress.vue'),
  },
  {
    meta: {
      title: "快递寄送审批"
    },
    name: 'InExpress',
    path: '/express/in',
    component: () => import('../components/components/inExpress.vue'),
  },
  {
    meta: {
      title: "申请发票"
    },
    name: 'NewInvoice',
    path: '/invoice/new',
    component: () => import('../components/components/NewInvoice.vue'),
  },
  {
    meta: {
      title: "发票清单"
    },
    name: 'InvoiceList',
    path: '/invoice/list',
    component: () => import('../components/components/InvoiceList.vue'),
  },
  ]
})

// 输出router
export default router;